<template>
  <PageLoader v-if="pageLoading" :loading="pageLoading"> </PageLoader>

  <div v-else class="container_margin">
    <!-- start of Export Document modal -->
    <b-modal size="sm" id="export-legislation" centered hide-footer hide-header>
      <h5 class="text-blue font-weight-bold">{{ "Export To" }}</h5>

      <div class="row">
        <div class="col">
          <div class="row mt-1">
            <div class="col">
              <div
                class="row mt-1"
                v-for="(export_item, export_item_normal) in exports_items"
                :key="'export_pdf' + export_item_normal"
              >
                <div class="col-2">
                  <input
                    :name="'pdf' + export_item_normal"
                    :id="'pdf' + export_item_normal"
                    type="checkbox"
                    :value="export_item.name"
                    v-model="pdf_status"
                    class="form-check-input mx-auto"
                  />
                </div>
                <div class="col-2">
                  <span
                    class="material-icons-outlined icon-export-blue justify-content-center"
                  >
                    {{ export_item.icon }}
                  </span>
                </div>

                <div class="col">
                  <b class="text-blue"> {{ export_item.name }} </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col align-self-center"></div>
        <div class="col-4 align-self-center">
          <button @click="exportContent" class="btn btn-primary">
            {{ "Export" }}
          </button>
        </div>
      </div>
    </b-modal>
    <!-- end of Export Document modal -->

    <!-- start of Legislation header -->
    <div class="card p-3">
      <div class="row">
        <div class="col">
          <h5 class="font-weight-bold text-blue">{{ "Legislation" }}</h5>
          <!-- <p class="mb-0">{{ legisName }}</p> -->
          <p class="mb-0" v-html="formattedText(legisName)"></p>
        </div>
        <div class="col">
          <h5 class="font-weight-bold text-blue">{{ "Jurisdiction" }}</h5>
          <p class="mb-0">{{ jurisdiction }}</p>
        </div>
        <div class="col-1 d-flex align-content-center justify-content-end">
          <button
            v-b-modal.export-legislation
            class="btn-primary btn my-auto float-right"
          >
            {{ "Export" }}
          </button>
        </div>
      </div>
    </div>
    <!-- end of Legislation header -->

    <div class="row">
      <div class="col-12 col-lg-6">
        <div
          v-if="
            full_content === 'not available yet' ||
            full_content === null ||
            full_content.length === 0
          "
          class="row mt-4"
        >
          <div class="col">
            <h5 class="text-blue text-center font-weight-bold">
              {{ "Legislation Summary" }}
            </h5>
          </div>
        </div>

        <div v-else class="row">
          <div class="col">
            <!--
              The export function is not able to convert BAILII's Legislation content to PDF
              So the button is hidden for these Legislation, further investigation required
            -->
            <button
              v-if="this.full_content.type === 'html' && !this.full_content.value.includes('BAILII')"
              class="btn btn-primary float-right mt-3"
              @click="ExportData"
            >
              Export to PDF
            </button>
            <h4 :class="['text-blue mt-4',
              {'text-center': this.full_content.type != 'html' || this.full_content.value.includes('BAILII')}
            ]">
              <b>{{ "Complete Legislation" }}</b>
            </h4>
          </div>
        </div>

        <!-- start of Content Section -->
        <div class="col">
          <div
            :class="[
              'card border shadow-sm p-3 rounded mt-2 key_Card h-100',
              { pdf: this.full_content.type === 'pdf' },
            ]"
          >
            <!-- if there is no full content -->
            <div
              v-if="
                full_content === 'not available yet' ||
                full_content === null ||
                full_content.length === 0
              "
            >
              <div class="row content_tag mt-1">
                <div class="col">
                  <b class="text-blue"> {{ "Key Points" }} </b>
                </div>
              </div>

              <div
                v-for="(key_point, index) in key_points_original"
                :key="'key_points' + index"
              >
                <div class="border-bottom m-1 p-1">
                  <div class="row">
                    <div class="col-1">
                      <p class="ner_content font-weight-bold float-left">
                        {{ index + 1 }}
                      </p>
                    </div>
                    <div class="col">
                      <p class="ner_content">{{ key_point }}</p>
                    </div>
                    <div class="col-1">
                      <span
                        v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                        :title="
                          buttonStates[index].isSpeaking ? 'Pause' : 'Audio'
                        "
                        @click="toggleSpeechKeyPoints(key_point, index)"
                        class="float-right cursor-pointer material-symbols-outlined icon-blue"
                      >
                        {{
                          buttonStates[index].isSpeaking
                            ? "pause"
                            : "text_to_speech"
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- if there is full content -->
            <div v-else>
              <div>
                <div v-if="this.full_content.type === 'html'">
                  <div class="m-1 p-1 border-bottom mb-3">
                    <div class="row">
                      <div class="col">
                        <p v-html="sanitizedHTML"></p>
                      </div>
                    </div>
                  </div>
                  <p class="font-10 text-muted">
                    NexLaw does not hold any copyright for the legislation
                    content. The content within this material, including but not
                    limited to text, images, graphics, logos, audio clips, and
                    software, belongs to various legislation databases depending
                    on the country and is protected by copyright laws.
                  </p>
                </div>

                <div v-else-if="this.full_content.type === 'pdf'">
                  <div class="m-1 p-1 border-bottom mb-3">
                    <div class="row">
                      <div class="col">
                        <iframe
                          :src="this.full_content.value"
                          height="596px"
                          width="100%"
                          frameborder="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  <p class="font-10 text-muted">
                    NexLaw does not hold any copyright for the legislation
                    content. The content within this material, including but not
                    limited to text, images, graphics, logos, audio clips, and
                    software, belongs to various legislation databases depending
                    on the country and is protected by copyright laws.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of Content Section -->
      </div>

      <div class="col-12 col-lg-6">
        <div class="row mt-4">
          <div class="col">
            <h4 class="text-blue text-center font-weight-bold">
              {{ "Legislation Summary" }}
            </h4>
          </div>
        </div>

        <!-- start of Summary Section -->
        <div class="col minH">
          <div class="card border shadow-sm p-3 rounded mt-2">
            <!-- start of Summary header -->
            <div class="d-flex mb-3 justify-content-between">
              <div class="tabs__folderTabs mt-auto">
                <button
                  @click="selectedTab = 'caseDocument'"
                  :class="{
                    folderTab: selectedTab === 'caseDocument',
                    active: selectedTab === 'caseDocument',
                  }"
                >
                  <span class="folderTab__title">{{ "Case Summary" }}</span>
                </button>
                <button
                  v-if="permissions.includes('nexlaw.qbot')"
                  @click="selectedTab = 'qBot'"
                  :class="{
                    folderTab: selectedTab === 'qBot',
                    active: selectedTab === 'qBot',
                  }"
                >
                  <span class="folderTab__title"
                    >{{ "LawBot" }}
                    <b-icon
                      v-if="selectedTab !== 'qBot'"
                      icon="stars"
                      class="mx-1"
                      font-scale="1.3"
                      animation="fade"
                    ></b-icon>

                    <b-icon
                      v-else
                      icon="stars"
                      class="mx-1"
                      font-scale="1.3"
                    ></b-icon>
                  </span>
                </button>
              </div>

              <div
                v-if="selectedTab === 'caseDocument'"
                class="d-flex align-items-center"
              >
                <span
                  v-b-tooltip.hover.top="{ variant: 'secondary' }"
                  title="Copy"
                  @click="copyContent"
                  class="cursor-pointer material-icons-outlined icon-blue mx-2"
                >
                  content_copy
                </span>
              </div>
            </div>
            <!-- end of Summary header -->

            <div v-if="selectedTab == 'caseDocument'" class="row summary_card">
              <div class="col">
                <div class="row content_tag mt-1">
                  <div class="col-12">
                    <p class="font-12 text-muted">
                      Note: The following summary is a concise representation
                      extracted from the first 50 pages, encapsulating the
                      initial 30,000 characters.
                    </p>
                  </div>

                  <div class="col">
                    <b class="text-blue"> {{ "Brief Summary" }} </b>
                  </div>
                  <div class="col">
                    <span
                      v-b-tooltip.hover.top="{ variant: 'secondary' }"
                      :title="isSpeaking ? 'Pause' : 'Audio Summary'"
                      @click="toggleSpeech(content_original)"
                      class="float-right cursor-pointer material-symbols-outlined icon-blue"
                    >
                      {{ isSpeaking ? "pause" : "text_to_speech" }}
                    </span>
                  </div>
                </div>

                <p class="mt-3 ner_content">{{ content_original }}</p>

                <div v-if="full_content !== 'not available yet'">
                  <div class="row content_tag mt-4">
                    <div class="col">
                      <b class="text-blue"> {{ "Key Points" }} </b>
                    </div>
                  </div>

                  <div
                    v-for="(key_point, index) in key_points_original"
                    :key="'key_points' + index"
                  >
                    <div class="border-bottom m-1 p-1">
                      <div class="row">
                        <div class="col-1">
                          <p class="ner_content font-weight-bold float-left">
                            {{ index + 1 }}
                          </p>
                        </div>
                        <div class="col">
                          <p class="ner_content">{{ key_point }}</p>
                        </div>
                        <div class="col-1">
                          <span
                            v-b-tooltip.hover.left="{ variant: 'secondary' }"
                            :title="
                              buttonStates[index].isSpeaking ? 'Pause' : 'Audio'
                            "
                            @click="toggleSpeechKeyPoints(key_point, index)"
                            class="float-right cursor-pointer material-symbols-outlined icon-blue mt-1"
                          >
                            {{
                              buttonStates[index].isSpeaking
                                ? "pause"
                                : "text_to_speech"
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="selectedTab === 'qBot'"
              class="card border-0"
              style="height: 639px"
            >
              <div class="chat__box" id="messages" ref="messages1">
                <!-- Incoming Chat box -->
                <div
                  v-for="(message, idx) in chatMessages"
                  :key="idx"
                  :class="message.class_1"
                >
                  <div :class="message.class_1">
                    <div :class="message.class_3">
                      <!-- <vue-typer
                        @vueTyper:done="scrollToBottom"
                        :repeat="0"
                        v-if="message.new"
                        :text="message.text"
                      ></vue-typer> -->

                      <span
                        v-if="message.new"
                        v-html="
                          message.text
                            .replace(/\\n*/g, '<br>')

                            .replace(/\\n\\n*/g, '<br>')
                            .replace(/\\n\\n/g, '<br>')
                            .replace(/\\n/g, '<br>')
                            .replace(/\n/g, '<br>')
                            .replace(/\n\n*/g, '<br>')
                            .replace('*', '')
                            .replace(/\\/g, '<br>')
                        "
                      ></span>

                      <span
                        v-else
                        v-html="
                          message.text
                            .replace(/\\n*/g, '<br>')

                            .replace(/\\n\\n*/g, '<br>')
                            .replace(/\\n\\n/g, '<br>')
                            .replace(/\\n/g, '<br>')
                            .replace(/\n/g, '<br>')
                            .replace(/\n\n*/g, '<br>')
                            .replace('*', '')
                            .replace(/\\/g, '<br>')
                        "
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- Outgoing chat box -->

                <div v-if="loadAnswer" class="chat__incoming">
                  <div class="chat__bubble__wrapper-incoming">
                    <div
                      class="chat__bubble-incoming animated-background-incoming"
                    >
                      <div
                        style="transform: scale(0.7)"
                        class="circles-to-rhombuses-spinner"
                      >
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="chat__suggestion"
                v-for="(question, index) in default_questions.slice(0, 3)"
                :key="index"
                @click="chooseMessage(question, index)"
                :disabled="loadAnswer || question.displayed"
              >
                <span>{{ question.text }}</span>
              </button>

              <div class="chat__input">
                <input
                  :disabled="loadAnswer"
                  @keyup.enter="sendNewMessage"
                  type="text"
                  v-model="NewText"
                  placeholder="Ask Questions about the Case..."
                />
                <button
                  v-if="NewText.length > 0"
                  @click="sendNewMessage"
                  :disabled="loadAnswer"
                  class="btn-send"
                >
                  <span class="material-icons-sharp"> arrow_circle_right </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end of Summary Section -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import LegislationSearch from "@/store/LegislationSearch.js";
import Auth from "@/store/Auth.js";
import { sanitizeHTML, ExportData, ExportDataPDF } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      legisName: "",
      jurisdiction: "",
      pageLoading: true,
      content_to_copy: "",
      content_to_export: "",
      content: "",
      exports_items: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],
      pdf_status: [],
      key_points_original: [],
      full_content: [],
      isSpeaking: false,
      utterance: null,
      buttonStates: [],
      content_original: "",
      duration: 100,
      selectedTab: "caseDocument",

      // chatbot
      default_questions: [
        {
          text: "What are the key points of this legislation? List in bullet points.",
          displayed: false,
        },
      ],
      NewText: "",
      loadAnswer: false,
      chatMessages: [],
    };
  },

  computed: {
    sanitizedHTML() {
      return sanitizeHTML(this.full_content.value);
    },
  },

  methods: {
    ExportData() {
      ExportDataPDF(
        sanitizeHTML(this.full_content.value)
      );
      this.$toast.success('Successfully downloaded PDF');
    },

    formattedText(text) {
      return text.replace(/<b>/g, '<strong>').replace(/<\/b>/g, '</strong>');
    },

    initializeUtterance(speechText) {
      console.log(speechText);
      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        this.isSpeaking = true;
        if (this.utterance) {
          console.log("Utterance is not null:", this.utterance);
        }
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        this.isSpeaking = false;
      };
    },

    initializeUtteranceKeyPoints(speechText, index) {
      const currentButtonState = this.buttonStates[index];

      console.log(speechText);

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        currentButtonState.isSpeaking = true;
        if (this.utterance) {
          console.log("Utterance is not null:", this.utterance);
        }
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        currentButtonState.isSpeaking = false;
      };
    },

    toggleSpeech(text) {
      var speechText = text;
      console.log(text);
      console.log(this.isSpeaking);

      if (!this.isSpeaking) {
        console.log("Utterance:", this.utterance);

        if (
          this.utterance &&
          this.utterance.text !== "" &&
          this.utterance.text === speechText
        ) {
          console.log("Resume");
          try {
            speechSynthesis.resume();
            this.isSpeaking = true;
          } catch (error) {
            console.error("Failed to resume:", error);
            console.log("Restarting");
            this.initializeUtterance(speechText);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          console.log("Cancel");
          speechSynthesis.cancel();
          this.isSpeaking = false;

          console.log("Start");
          this.initializeUtterance(speechText);
          console.log("Utterance:", this.utterance);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        console.log("Pause");
        speechSynthesis.pause();
        this.isSpeaking = false;

        this.utterance.onpause = () => {
          console.log("Paused successfully");
          this.isSpeaking = false;
          this.utterance.onpause = null; // Remove the event listener after handling
        };
      }
    },

    toggleSpeechKeyPoints(text, index) {
      var speechText = text;
      const currentButtonState = this.buttonStates[index];

      console.log(text);
      console.log(currentButtonState.isSpeaking);

      if (!currentButtonState.isSpeaking) {
        console.log("Utterance:", this.utterance);

        if (
          this.utterance &&
          this.utterance.text !== "" &&
          this.utterance.text === speechText
        ) {
          console.log("Resume");
          try {
            speechSynthesis.resume();
            currentButtonState.isSpeaking = true;
          } catch (error) {
            console.error("Failed to resume:", error);
            console.log("Restarting");
            this.initializeUtteranceKeyPoints(speechText, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          console.log("Cancel");
          speechSynthesis.cancel();
          currentButtonState.isSpeaking = false;

          console.log("Start");
          this.initializeUtteranceKeyPoints(speechText, index);
          console.log("Utterance:", this.utterance);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        console.log("Pause");
        speechSynthesis.pause();
        currentButtonState.isSpeaking = false;

        this.utterance.onpause = () => {
          console.log("Paused successfully");
          currentButtonState.isSpeaking = false;
          this.utterance.onpause = null; // Remove the event listener after handling
        };
      }
    },

    exportContent() {
      if (this.pdf_status.length === 0) {
        this.$toast.error("Kindly select a format to export");
        return;
      }

      var status = ExportData(this.content_to_export, [
        this.pdf_status.includes("DOCUMENT"),
        this.pdf_status.includes("PDF"),
      ]);

      if (status[0]) {
        this.$toast.success("Successfully downloaded Document");
      }
      if (status[1]) {
        this.$toast.success("Successfully downloaded PDF");
      }

      this.pdf_status = [];
      this.$bvModal.hide("export-legislation");
    },

    copyContent() {
      navigator.clipboard.writeText(this.content_to_copy);

      this.$toast.success("Copied");
    },

    chooseMessage(message, index) {
      this.loadAnswer = true;
      this.chatMessages.push({
        text: message.text,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      // Update the displayed property of the chosen question
      this.default_questions[index].displayed = true;
      // // Find the next question that hasn't been displayed
      // const nextQuestion = this.default_questions
      //   .find((question) => !question.displayed);

      var obj = {
        question: message.text,
        mask: "true",
      };

      // console.log("Passed into API", obj);
      // return;
      LegislationSearch.sendQuestion("legislation", this.$route.params.id, obj)
        .then(() => {
          this.default_questions.splice(
            this.default_questions.findIndex((x) => x.text === message.text),
            1
          );
          this.calltime("default");
        })
        .catch((error) => {
          console.log(error);
          this.loadAnswer = false;
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: this.NewText,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });

        var obj = {
          question: this.NewText,
          mask: "true",
        };
        this.NewText = "";

        // console.log("Passed into API", obj);
        // return;
        LegislationSearch.sendQuestion(
          "legislation",
          this.$route.params.id,
          obj
        )
          .then(() => {
            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              const credits = res.data.data.nexlaw_credits;
              this.contract_questions_full = credits.full.contract_questions;
              this.contract_questions_remain =
                credits.full.contract_questions -
                credits.used.contract_questions;
            });
            this.calltime("none");
          })
          .catch((error) => {
            console.log(error);
            this.loadAnswer = false;
            this.loadAnswer = false;
            console.log(error);
            this.loadAnswer = false;
            console.log(error);
          });

        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      }
    },

    calltime(data) {
      var me = this;
      setTimeout(function () {
        if (!me.$route.params.id) return;
        
        LegislationSearch.OpenSummary(me.$route.params.id)
          .then((response) => {
            const questions = response.data.data.questions;
            const last_q = questions[questions.length - 1];
            if (last_q.answer != null) {
              try {
                JSON.parse(last_q.answer).forEach((answer) => {
                  me.chatMessages.push({
                    text: answer,
                    class_1: "chat__incoming",
                    class_2: "chat__bubble__wrapper-incoming",
                    class_3:
                      "chat__bubble-incoming animated-background-incoming",
                    new: true,
                  });
                });
              } catch (error) {
                me.chatMessages.push({
                  text: last_q.answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: true,
                });
              }
              me.loadAnswer = false;
            } else {
              me.calltime(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    },

    scrollToBottom() {
      // Scroll to the bottom of the chat container
      this.$nextTick(() => {
        const chatContainer = this.$refs.messages1;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
  },

  created() {
    LegislationSearch.OpenSummary(this.$route.params.id)
      .then((response) => {
        this.pageLoading = false;
        // console.log(response.data.data);

        this.legisName = response.data.data.name;
        this.jurisdiction = response.data.data.jurisdiction;

        if (this.jurisdiction == "United States") {
          this.jurisdiction += " of America";
        }

        // if (response.data.data.content) {
        //   this.case_file = response.data.data.content;

        //   // .replace(".html", ".pdf");
        // }

        // console.log(this.case_file);

        const foundbrief_summary = response.data.data.values.find(
          (element) => element.key === "brief_summary"
        );

        this.content_original = foundbrief_summary.value;

        const foundkey_points = response.data.data.values.find(
          (element) => element.key === "key_points"
        );

        this.key_points_original = JSON.parse(foundkey_points.value);

        this.buttonStates = Array.from(
          { length: this.key_points_original.length },
          () => ({
            isSpeaking: false,
          })
        );

        // Full content html/pdf
        this.full_content = response.data.data.full_content;

        if (this.full_content.type === "html") {
          this.full_content.value = this.full_content.value.replace(
            'link="blue" style="word-wrap:break-word" vlink="#954F72">',
            ""
          );
        }

        this.content_to_copy =
          `Brief Summary \n\n` + this.content_original + `\n\nKey Points`;

        this.key_points_original.forEach((element, index) => {
          this.content_to_copy =
            this.content_to_copy + `\n\n${index + 1}) ` + element;
        });

        this.content_to_export = `<b>Legislation</b><br>` + this.legisName;

        this.content_to_export =
          this.content_to_export +
          `<br><br><b>Brief Summary</b><br>` +
          this.content_original +
          `<br><br><b>Key Points</b>`;

        this.key_points_original.forEach((element, index) => {
          this.content_to_export =
            this.content_to_export + `<br>` + (index + 1) + `) ` + element;
        });

        response.data.data.questions.forEach((element) => {
          if (element.answer.length != 0) {
            this.default_questions.splice(
              this.default_questions[
                this.default_questions.findIndex((x) => x === element.question)
              ],
              1
            );

            this.chatMessages.push({
              text: element.question,
              class_1: "chat__outgoing",
              class_2: "chat__bubble__wrapper-outgoing",
              class_3: "chat__bubble-outgoing animated-background-outgoing",
              new: false,
            });

            try {
              JSON.parse(element.answer).forEach((answer) => {
                this.chatMessages.push({
                  text: answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: false,
                });
              });
            } catch (error) {
              this.chatMessages.push({
                text: element.answer,
                class_1: "chat__incoming",
                class_2: "chat__bubble__wrapper-incoming",
                class_3: "chat__bubble-incoming animated-background-incoming",
                new: false,
              });
            }
          }
        });

        if (
          response.data.data.questions.length != 0 &&
          response.data.data.questions[response.data.data.questions.length - 1]
            .answer == null
        ) {
          this.loadAnswer = true;
          this.calltime("none");

          this.chatMessages.push({
            text: response.data.data.questions[
              response.data.data.questions.length - 1
            ].question,
            class_1: "chat__outgoing",
            class_2: "chat__bubble__wrapper-outgoing",
            class_3: "chat__bubble-outgoing animated-background-outgoing",
            new: false,
          });
        }

        if (this.chatMessages.length == 0) {
          this.chatMessages.push({
            text: "You may ask question about the case here🔎",
            class_1: "chat__incoming",
            class_2: "chat__bubble__wrapper-incoming",
            class_3: "chat__bubble-incoming animated-background-incoming",
            new: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.container_margin {
  padding: 1rem 2rem;
}

.icon-export-blue {
  color: #094890;
  font-size: 2rem;
}

.ner_badge {
  border-radius: 10px;
  border: none;
  color: white;
  padding: 0.5rem;
}

.ner_content ::v-deep .ner_badge {
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
}

.badge_color {
  background: #fda355;
}

.ner_content ::v-deep .badge_color {
  background: #fda355;
}

.ner_content {
  line-height: 2rem;
}

.popover {
  max-width: 500px !important;
}

.ner_content ::v-deep .LAWYER {
  background: #7edfff;
}

.ner_content ::v-deep .COURT {
  background: #9acd32;
}

.ner_content ::v-deep .JUDGE {
  background: #c6bfff;
}

.ner_content ::v-deep .PETITIONER {
  background: #ff7e7e;
}

.ner_content ::v-deep .RESPONDENT {
  background: #ffa500;
}

.ner_content ::v-deep .CASE_NUMBER {
  background: #bcc2d8;
}

.ner_content ::v-deep .GPE {
  background: #87cefa;
}

.ner_content ::v-deep .DATE {
  background: #c1ffc1;
}

.ner_content ::v-deep .ORG {
  background: #ffdd7f;
}

.ner_content ::v-deep .STATUTE {
  background: #f5deb3;
}

.ner_content ::v-deep .WITNESS {
  background: #ffff8f;
}

.ner_content ::v-deep .PRECEDENT {
  background: #cbe3e2;
}

.ner_content ::v-deep .PROVISION {
  background: #f4a460;
}

.ner_content ::v-deep .OTHER_PERSON {
  background: #cbcbcb;
}

.table .LAWYER {
  background: #7edfff;
}

.table .COURT {
  background: #9acd32;
}

.table .JUDGE {
  background: #c6bfff;
}

.table .PETITIONER {
  background: #ff7e7e;
}

.table .RESPONDENT {
  background: #ffa500;
}

.table .CASE_NUMBER {
  background: #bcc2d8;
}

.table .GPE {
  background: #87cefa;
}

.table .DATE {
  background: #c1ffc1;
}

.table .ORG {
  background: #ffdd7f;
}

.table .STATUTE {
  background: #f5deb3;
}

.table .WITNESS {
  background: #ffff8f;
}

.table .PRECEDENT {
  background: #cbe3e2;
}

.table .PROVISION {
  background: #f4a460;
}

.table .OTHER_PERSON {
  background: #cbcbcb;
}

.table .Orange_1 {
  background: #ffdd7f;
}

.table .Orange_2 {
  background: #f5deb3;
}

.table .Orange_3 {
  background: #f4a460;
}

.table {
  --bs-table-bg: none;
}

.table_card {
  max-height: 500px !important;
  overflow-x: scroll;
}

.summary_card {
  max-height: 639px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.key_Card {
  max-height: 726px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.key_Card.pdf {
  overflow-y: hidden !important;
}

.toggle {
  font-size: 32px;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.material-icons-outlined.when-closed {
  transform: rotate(0deg);
  transition: all 0.4s ease-out;
}

.material-icons-outlined.when-open {
  transform: rotate(180deg);
  transition: all 0.4s ease-out;
}

.table {
  border: none;
  background: white;
  table-layout: fixed;
  font-size: 14px;
}

tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.form-check-input:checked {
  border-color: #094890;
  background-color: #094890;
}

.form-switch .form-check-input {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23094890'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus:checked {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  box-shadow: #094890;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23094890'/%3e%3c/svg%3e");
}

/* chatBot Css */
.animated-background-outgoing {
  background: linear-gradient(
    45deg,
    #012741,
    #004877,
    #006bb3,
    #0866a5,
    #0976be
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: linear-gradient(
    45deg,
    #cccaca,
    #bcbebe,
    #d4cece,
    #d3d4d4,
    #f1f1f1
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.card_bot_question {
  height: 700px;
  overflow-y: scroll;
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: #86d4f8;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
  transition: 300ms ease-out;
}

.chat__input .btn-send:hover span {
  color: #004278;
}

.accordion-checkbox {
  display: none;
}

.accordion-label {
  cursor: pointer;
  display: block;
}

.accordion-icon {
  transition: transform 0.3s;
}

.accordion-checkbox:checked ~ .accordion-label .accordion-icon {
  transform: rotate(-180deg);
}

.card-header {
  padding: 0rem 0rem !important;
  padding-top: 1rem !important;
  margin-bottom: 0 !important;
  color: var(--bs-card-cap-color) !important;
  background-color: #ffffff !important;
  border-bottom: 0px solid #ffffff !important;
}

.tabs__folderTabs {
  text-wrap: nowrap;
}
</style>
